const MapboxDraw = require('@mapbox/mapbox-gl-draw');
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import mouseEventPoint from '@mapbox/mapbox-gl-draw/src/lib/mouse_event_point';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import { nearestPointOnLine as turfNearestPointOnLine } from '@turf/turf';
import constants from '~/shared/constants';
import {
  getFromPropertyName,
  getToPropertyName,
  isEdgeOrNetworkSegment,
  isNodeOrJunction
} from '~/shared/utils';

import { store } from '../../store';
import {
  EDGE_TYPE,
  NODE_TYPE,
  addMeasureGuideToMap,
  createAngleMeasureGuides,
  deleteMeasureGuides,
  getEdgesByNodes
} from './measureGuides';

const SimpleSelectModeOverride = MapboxDraw.modes.simple_select;

SimpleSelectModeOverride.onClick = function(state, e) {
  deleteMeasureGuides(state, this);
  if (store.getters['venue/editingVenueConnections']) {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return store.dispatch('venue/cancelExchangePoint');
    }

    if (CommonSelectors.isFeature(e)) {
      const feature = this.getFeature(e.featureTarget.properties.id);

      if (
        feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
        feature.properties.type === constants.JUNCTION_TYPES.ENTRY_POINT
      ) {
        if (!this.isSelected(feature.id)) {
          store.dispatch('venue/setConnectionEndpoint', feature.id);
          return this.clickOnFeature(state, e);
        }
      } else if (
        !store.getters['venue/siteToHide'] &&
        feature.properties._type === constants.FEATURE_TYPES.VENUE_CONNECTION
      ) {
        return store.dispatch('venue/removeExchangePoint', feature.id);
      }
    }
    return store.dispatch('venue/setConnectionEndpoint');
  } else {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return this.changeMode('simple_select');
    }

    if (CommonSelectors.isFeature(e)) {
      const feature = this.getFeature(e.featureTarget.properties.id);

      if (
        (!this.isSelected(feature.id) || !isEdgeOrNetworkSegment(feature)) &&
        !(
          isNodeOrJunction(feature) &&
          store.getters['status/currentMainMode'] === constants.MAIN_MODES.ZONE
        )
      ) {
        return this.clickOnFeature(state, e);
      } else {
        this.deselect(feature.id);
        if (!CommonSelectors.isShiftDown(e)) {
          this.clearSelectedFeatures();
        }
      }
    } else {
      if (!CommonSelectors.isShiftDown(e)) {
        this.clearSelectedFeatures();
      }
      return;
    }
  }
};

SimpleSelectModeOverride.onMouseUp = function(state, e) {
  if (!store.getters['venue/editingVenueConnections']) {
    deleteMeasureGuides(state, this);
    const boxSelecting = state.boxSelecting;
    if (state.dragMoving) {
      this.fireUpdate();
    } else if (boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ];
      let featuresInBox = this.featuresAt(null, bbox, 'click');
      if (store.getters['status/currentMainMode'] === constants.MAIN_MODES.ZONE) {
        featuresInBox = featuresInBox.filter(
          (feature) => feature.properties.user__type !== constants.FEATURE_TYPES.NODE
        );
      }
      const idsToSelect = this.getUniqueIds(featuresInBox).filter((id) => !this.isSelected(id));

      if (idsToSelect.length) {
        this.select(idsToSelect);
        idsToSelect.forEach((id) => this.doRender(id));
        this.updateUIClasses({ mouse: Constants.cursors.MOVE });
      }
    }
    this.stopExtendedInteractions(state);
    state.dragPosition = {};

    if (!boxSelecting) {
      // selected nodes and junctions (except for refnodes)
      const points = this.getSelected().filter(
        (feature) =>
          isNodeOrJunction(feature) ||
          feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
      );

      if (!e.originalEvent.altKey && points.length === 1) {
        for (let point of points) {
          // features on the coordinates of the node/junction
          const renderedFeatures = this._ctx.map.queryRenderedFeatures(
            this._ctx.map.project(point.coordinates)
          );

          if (point.properties._type !== constants.FEATURE_TYPES.REFERENCE_NODE) {
            const segmentType =
              point.properties._type === constants.FEATURE_TYPES.NODE
                ? constants.FEATURE_TYPES.EDGE
                : constants.FEATURE_TYPES.NETWORK_SEGMENT;

            const fromPropertyName = getFromPropertyName(segmentType);
            const toPropertyName = getToPropertyName(segmentType);

            // all edges/segments on those coordinates not ending there (segments to split)
            const renderedSegments = renderedFeatures.filter(
              (feature) =>
                feature.properties.user__type === segmentType &&
                feature.properties['user_' + fromPropertyName] !== point.id &&
                feature.properties['user_' + toPropertyName] !== point.id
            );

            // all nodes/junctions on those coordinates (except for itself)
            const renderedPoints = renderedFeatures.filter(
              (feature) =>
                feature.properties.user__type === point.properties._type &&
                feature.properties.id !== point.id
            );

            if (renderedPoints && renderedPoints.length > 0) {
              let renderedJunctionIds = [];
              for (const renderedPoint of renderedPoints) {
                if (
                  renderedPoint.properties.user__type === constants.FEATURE_TYPES.JUNCTION &&
                  !renderedJunctionIds.includes(renderedPoint.properties.id)
                ) {
                  renderedJunctionIds.push(renderedPoint.properties.id);
                }
              }
              // true, if we are close to a special junction, otherwise false
              let reverseSnap = false;
              if (renderedJunctionIds.length === 1) {
                const renderedJunction = this.getFeature(renderedJunctionIds[0]);
                if (renderedJunction.properties.type !== constants.JUNCTION_TYPES.NORMAL) {
                  reverseSnap = true;
                } else {
                  this._ctx.api.setFeatureProperty(
                    renderedJunction.id,
                    'type',
                    point.properties.type
                  );
                }
              }
              for (const renderedPoint of renderedPoints) {
                // point on the same coordinates (if !reverseSnap)
                const referencePointId = reverseSnap ? point.id : renderedPoint.properties.id;
                // original (moved) point (if !reverseSnap)
                const otherPointId = reverseSnap ? renderedPoint.properties.id : point.id;
                //get lines connected to (original, if !reverseSnap) point
                const connectedLines = this._ctx.api
                  .getAll()
                  .features.filter(
                    (feature) =>
                      feature.properties._type === segmentType &&
                      (feature.properties[fromPropertyName] === otherPointId ||
                        feature.properties[toPropertyName] === otherPointId)
                  );
                this._ctx.api.getAll().features.forEach((feature) => {
                  // edge/segment from the point on the same coordinates
                  if (feature.properties[fromPropertyName] === referencePointId) {
                    let overlap = false;
                    for (let line of connectedLines) {
                      if (
                        feature.properties[toPropertyName] === line.properties[fromPropertyName] ||
                        feature.properties[toPropertyName] === line.properties[toPropertyName]
                      ) {
                        // we are snapping to a point,
                        // that has a segment with the moved point between them
                        overlap = true;
                      }
                    }
                    if (overlap) {
                      // delete segment if its ends are snapped to each other
                      this.deleteFeature(feature.id, { silent: true });
                    } else {
                      // connect end of segment to snapped point, because old one will be deleted
                      this.getFeature(feature.id).properties[fromPropertyName] = otherPointId;
                    }
                  }

                  if (feature.properties[toPropertyName] === referencePointId) {
                    let overlap = false;
                    for (let line of connectedLines) {
                      if (
                        feature.properties[fromPropertyName] ===
                          line.properties[fromPropertyName] ||
                        feature.properties[fromPropertyName] === line.properties[toPropertyName]
                      ) {
                        overlap = true;
                      }
                    }
                    if (overlap) {
                      this.deleteFeature(feature.id, { silent: true });
                    } else {
                      this.getFeature(feature.id).properties[toPropertyName] = otherPointId;
                    }
                  }
                });

                // align the moving point to the center of the rendered point
                point.incomingCoords(renderedPoint.geometry.coordinates);
                this.moveEdges(state);

                this.deleteFeature(referencePointId, { silent: true });
              }
            }

            // delete edges/segments that are left with both ends as the same node/junction
            this._ctx.api.getAll().features.forEach((feature) => {
              if (
                feature.properties[fromPropertyName] &&
                feature.properties[fromPropertyName] === feature.properties[toPropertyName]
              ) {
                this.deleteFeature(feature.id, { silent: true });
              }
            });

            if (renderedPoints.length) {
              continue;
            }

            // split segments that are under the point
            for (let renderedSegment of renderedSegments) {
              let segmentToSplit = this.getFeature(renderedSegment.properties.id);

              // align the point to the centre of the segment
              // (the segment to split has a width by style)
              const nearestPoint = turfNearestPointOnLine(segmentToSplit, point.coordinates);
              point.incomingCoords(nearestPoint.geometry.coordinates);
              this.moveEdges(state);

              const originalTo = {
                toId: segmentToSplit.properties[toPropertyName],
                coordinates: segmentToSplit.coordinates[1]
              };

              // is the moved segment in the same position as the shortened segment
              let segmentAlreadyExists = this._ctx.api
                .getAll()
                .features.some(
                  (feature) =>
                    (feature.properties[fromPropertyName] ===
                      segmentToSplit.properties[fromPropertyName] &&
                      feature.properties[toPropertyName] === point.id) ||
                    (feature.properties[fromPropertyName] === point.id &&
                      feature.properties[toPropertyName] ===
                        segmentToSplit.properties[fromPropertyName])
                );

              if (segmentAlreadyExists) {
                this.deleteFeature(segmentToSplit.id);
              } else {
                segmentToSplit.properties[toPropertyName] = point.id;
                segmentToSplit.incomingCoords([segmentToSplit.coordinates[0], point.coordinates]);
              }

              // is the moved segment in the same position as the newly created segment
              segmentAlreadyExists = this._ctx.api
                .getAll()
                .features.some(
                  (feature) =>
                    (feature.properties[fromPropertyName] === point.id &&
                      feature.properties[toPropertyName] === originalTo.toId) ||
                    (feature.properties[fromPropertyName] === originalTo.toId &&
                      feature.properties[toPropertyName] === point.id)
                );

              if (!segmentAlreadyExists) {
                const newSegment = this.newFeature({
                  type: 'Feature',
                  properties: {
                    ...segmentToSplit.properties,
                    alreadyCreated: false,
                    id: undefined
                  },
                  geometry: {
                    type: 'LineString',
                    coordinates: [point.coordinates, originalTo.coordinates]
                  }
                });

                newSegment.properties[fromPropertyName] = point.id;
                newSegment.properties[toPropertyName] = originalTo.toId;

                this.addFeature(newSegment);
              }
            }
          } else {
            const renderedReferenceNodes = renderedFeatures.filter(
              (feature) =>
                feature.properties.user__type === point.properties._type &&
                feature.properties.id !== point.id
            );

            if (renderedReferenceNodes.length > 0) {
              // if there is snapping reference node, delete the moved reference node
              store.dispatch('referenceNode/delete', {
                id: point.id,
                levelId: point.properties.levelId
              });
              this.deleteFeature(point.id, { silent: true });
            }
          }
        }
      }
    }

    const elevators = this.getSelected().filter(
      (feature) => feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR
    );

    const referenceNodes = this.getSelected().filter(
      (feature) => feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
    );

    if (state.isMoved) {
      deleteMeasureGuides(state, this);

      if (elevators.length) {
        store.dispatch('elevator/move', elevators);
      }

      if (referenceNodes.length) {
        store.dispatch('referenceNode/move', referenceNodes);
      }

      state.isMoved = false;

      this.map.fire(constants.CUSTOM_DRAW_EVENTS.MODIFICATION, {
        isElevatorMoved: !!elevators.length
      });
    }
  }
};

/** Move edges together with the moving nodes */
SimpleSelectModeOverride.moveEdges = function(state) {
  if (!store.getters['venue/editingVenueConnections']) {
    const selectedNodes = this.getSelected().filter((feature) => isNodeOrJunction(feature));

    const edges = this._ctx.api
      .getAll()
      .features.filter((feature) => isEdgeOrNetworkSegment(feature));

    // used only for better understanding of the measure guide concept
    // measure guide: the length and angle values shown when one or more
    //                nodes and connecting edge are moved

    // primary nodes: nodes selected (highlighted) by the user
    // extra variable used only for better understanding of the measure guide concept
    const primaryNodes = selectedNodes;
    // primary edges: edges with at least one end connecting to primary node
    const primaryEdgesByPrimaryNodes = getEdgesByNodes({
      edges,
      nodes: primaryNodes,
      type: EDGE_TYPE.PRIMARY,
      drawMode: this
    });
    // primary edges at this point can be duplicated if an edge connects two primary node
    const primaryEdgesWithPossibleDuplications = Object.values(primaryEdgesByPrimaryNodes).flat();
    const primaryEdges = primaryEdgesWithPossibleDuplications.filter(
      (edge, index) =>
        primaryEdgesWithPossibleDuplications.findIndex((item) => item.id === edge.id) === index
    );

    // secondary nodes: the end nodes of primary edges that are not primary nodes
    const secondaryNodeIds = primaryEdges
      .map((edge) => [edge.properties.fromNode, edge.properties.toNode])
      .flat()
      .filter((nodeId) => !selectedNodes.map((node) => node.id).includes(nodeId));
    const secondaryNodes = this._ctx.api
      .getAll()
      .features.filter(
        (feature) => isNodeOrJunction(feature) && secondaryNodeIds.includes(feature.id)
      );
    // secondary edges: edges with at least one end connecting to secondary node
    //                  that are not primary edge
    const edgesBySecondaryNodes = getEdgesByNodes({
      edges,
      nodes: secondaryNodes,
      type: EDGE_TYPE.SECONDARY,
      existingEdges: primaryEdges
    });

    // delete existing measure guides
    deleteMeasureGuides(state, this);

    // add length values to measure guides
    for (let edge of primaryEdges) {
      addMeasureGuideToMap({
        drawMode: this,
        state,
        coordinates: edge.calculated.centerPoint,
        properties: {
          guide_length: (edge.calculated.length * 1000).toFixed(2) + ' m',
          guide_rotation:
            edge.calculated.bearing > 180
              ? edge.calculated.bearing + 90
              : edge.calculated.bearing - 90
        }
      });
    }

    // add angle values at primary nodes to measure guides
    for (const nodeId of Object.keys(primaryEdgesByPrimaryNodes)) {
      const primaryNode = primaryNodes.find((node) => node.id === nodeId);
      const primaryEdgesAtNode = primaryEdgesByPrimaryNodes[nodeId].sort(
        (curr, prev) => curr.calculated.bearing - prev.calculated.bearing
      );
      for (let idx = 0; idx < primaryEdgesAtNode.length; idx++) {
        const isLastEdge = idx === primaryEdgesAtNode.length - 1;
        const edge = primaryEdgesAtNode[idx];
        const nextEdge = primaryEdgesAtNode[isLastEdge ? 0 : idx + 1];

        createAngleMeasureGuides({
          node: {
            id: primaryNode.id,
            coordinates: primaryNode.coordinates,
            type: NODE_TYPE.PRIMARY
          },
          edge,
          nextEdge,
          state,
          drawMode: this
        });
      }
    }

    // add angle values at secondary nodes to measure guides
    for (const nodeId of Object.keys(edgesBySecondaryNodes)) {
      const node = secondaryNodes.find((node) => node.id === nodeId);
      const edgesAtNode = edgesBySecondaryNodes[nodeId]
        .map((edge) => {
          if (edge.calculated.type === EDGE_TYPE.PRIMARY) {
            edge.calculated.bearing =
              edge.calculated.bearing >= 180
                ? edge.calculated.bearing - 180
                : edge.calculated.bearing + 180;
          }

          return edge;
        })
        .sort((curr, prev) => curr.calculated.bearing - prev.calculated.bearing);
      // don't show angle measure guide if only 1 edge present at secondary node
      if (edgesAtNode.length > 1) {
        for (let idx = 0; idx < edgesAtNode.length; idx++) {
          const edge = edgesAtNode[idx];
          // add angle measure guide only to primary edges
          if (edge.calculated.type === EDGE_TYPE.PRIMARY) {
            const prevEdge = edgesAtNode[idx === 0 ? edgesAtNode.length - 1 : idx - 1];
            const nextEdge = edgesAtNode[idx === edgesAtNode.length - 1 ? 0 : idx + 1];

            // add angle measure guide to the left if not there already
            if (prevEdge.calculated.type === EDGE_TYPE.SECONDARY) {
              createAngleMeasureGuides({
                node: {
                  id: node.id,
                  coordinates: node.geometry.coordinates,
                  type: NODE_TYPE.SECONDARY
                },
                edge: prevEdge,
                nextEdge: edge,
                state,
                drawMode: this
              });
            }

            createAngleMeasureGuides({
              node: {
                id: node.id,
                coordinates: node.geometry.coordinates,
                type: NODE_TYPE.SECONDARY
              },
              edge: edge,
              nextEdge: nextEdge,
              state,
              drawMode: this
            });
          }
        }
      }
    }
  }
};

/** Withhold special junctions (stairs, elevator, etc.) from cover each other  */
SimpleSelectModeOverride.keepDistance = function(state, movableFeatures, delta) {
  if (!store.getters['venue/editingVenueConnections']) {
    const selectedSpecialJunctions = movableFeatures.filter(
      (feature) =>
        feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
        feature.properties.type !== constants.JUNCTION_TYPES.NORMAL
    );

    for (let junction of selectedSpecialJunctions) {
      let junctionCoords = [...junction.coordinates];

      // dragPosition field contains those coordinates of the junction
      // where it would be if it were not witheld or snapped
      if (state.dragPosition && state.dragPosition[junction.id]) {
        junctionCoords = state.dragPosition[junction.id];
      }

      const { x, y } = this._ctx.map.project([
        junctionCoords[0] + delta.lng,
        junctionCoords[1] + delta.lat
      ]);

      let renderedFeatures = this._ctx.map.queryRenderedFeatures([
        [x - constants.SNAPPING_DISTANCE_PX / 2, y - constants.SNAPPING_DISTANCE_PX / 2],
        [x + constants.SNAPPING_DISTANCE_PX / 2, y + constants.SNAPPING_DISTANCE_PX / 2]
      ]);

      if (
        renderedFeatures.some(
          (feature) =>
            feature.properties.user__type === constants.FEATURE_TYPES.JUNCTION &&
            feature.properties.user_type !== constants.JUNCTION_TYPES.NORMAL &&
            feature.properties.id !== junction.id
        )
      ) {
        state.dragPosition = state.dragPosition || {};
        state.dragPosition[junction.id] = [
          junctionCoords[0] + delta.lng,
          junctionCoords[1] + delta.lat
        ];
        movableFeatures.splice(
          movableFeatures.findIndex((feature) => feature.id === junction.id),
          1
        );
      } else {
        if (state.dragPosition && state.dragPosition[junction.id]) {
          movableFeatures[
            movableFeatures.findIndex((feature) => feature.id === junction.id)
          ].coordinates = state.dragPosition[junction.id];

          delete state.dragPosition[junction.id];
        }
      }
    }
  }
};

SimpleSelectModeOverride.snap = function(state, movedFeatures, delta) {
  if (!store.getters['venue/editingVenueConnections']) {
    const selectedPoints = movedFeatures.filter(
      (feature) =>
        isNodeOrJunction(feature) ||
        feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
    );

    for (let point of selectedPoints) {
      let coords = [...point.coordinates];

      // dragPosition field contains those coordinates of the point
      // where it would be if it were not witheld or snapped
      if (state.dragPosition && state.dragPosition[point.id]) {
        const storedCoords = state.dragPosition[point.id];
        coords = [storedCoords[0] + delta.lng, storedCoords[1] + delta.lat];
        delete state.dragPosition[point.id];
      }

      const { x, y } = this._ctx.map.project(coords);

      let renderedFeatures = this._ctx.map.queryRenderedFeatures([
        [x - constants.SNAPPING_DISTANCE_PX / 2, y - constants.SNAPPING_DISTANCE_PX / 2],
        [x + constants.SNAPPING_DISTANCE_PX / 2, y + constants.SNAPPING_DISTANCE_PX / 2]
      ]);

      // first try to snap to a point:
      // first try to snap to the same types...
      let pointToSnap = renderedFeatures.find(
        (feature) =>
          feature.properties.user__type === point.properties._type &&
          feature.properties.id !== point.id &&
          // selected features cannot be snapped to each other
          !this.getSelectedIds().includes(feature.properties.id)
      );
      // ...then try to snap to other points...
      if (!pointToSnap) {
        pointToSnap = renderedFeatures.find(
          (feature) =>
            (feature.properties.user__type === constants.FEATURE_TYPES.REFERENCE_NODE ||
              feature.properties.user__type === constants.FEATURE_TYPES.NODE) &&
            feature.properties.id !== point.id &&
            // selected features cannot be snapped to each other
            !this.getSelectedIds().includes(feature.properties.id)
        );
      }

      if (pointToSnap) {
        if (
          // special junctions cannot be snapped to special junctions
          point.properties._type === constants.FEATURE_TYPES.JUNCTION &&
          point.properties.type !== constants.JUNCTION_TYPES.NORMAL &&
          pointToSnap.properties.user__type === constants.FEATURE_TYPES.JUNCTION &&
          pointToSnap.properties.user_type !== constants.JUNCTION_TYPES.NORMAL
        ) {
          continue;
        }

        state.dragPosition = state.dragPosition || {};
        state.dragPosition[point.id] = coords;

        point.incomingCoords(pointToSnap.geometry.coordinates);

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !==
            JSON.stringify(pointToSnap.geometry.coordinates)
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: pointToSnap.geometry.coordinates
          });
          state.lastSnappingCoords = pointToSnap.geometry.coordinates;
        }
        continue;
      }

      // ...then try to snap to a segment
      const segmentType =
        point.properties._type === constants.FEATURE_TYPES.NODE ||
        point.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
          ? constants.FEATURE_TYPES.EDGE
          : constants.FEATURE_TYPES.NETWORK_SEGMENT;

      const fromPropertyName = 'user_' + getFromPropertyName(segmentType);
      const toPropertyName = 'user_' + getToPropertyName(segmentType);

      const segmentToSnap = renderedFeatures.find(
        (feature) =>
          feature.properties.user__type === segmentType &&
          feature.properties[fromPropertyName] !== point.id &&
          feature.properties[toPropertyName] !== point.id
      );

      if (segmentToSnap) {
        const nearestPoint = turfNearestPointOnLine(segmentToSnap, point.coordinates);

        state.dragPosition = state.dragPosition || {};
        state.dragPosition[point.id] = coords;
        point.incomingCoords(nearestPoint.geometry.coordinates);

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !==
            JSON.stringify(nearestPoint.geometry.coordinates)
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: nearestPoint.geometry.coordinates
          });
          state.lastSnappingCoords = nearestPoint.geometry.coordinates;
        }
        continue;
      }

      if (state.lastSnappingCoords) {
        this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
          coordinates: undefined
        });

        state.lastSnappingCoords = undefined;
      }
    }
  }
};

/** Detaches elevators from the network in free move mode */
SimpleSelectModeOverride.detachElevatorsFromNetwork = function() {
  if (!store.getters['venue/editingVenueConnections']) {
    const elevatorMoveType = store.getters['elevator/moveType'];

    if (elevatorMoveType === constants.ELEVATOR_MOVE_TYPES.FREE) {
      let elevators = this.getSelected().filter(
        (feature) => feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR
      );

      if (!elevators.length) {
        return;
      }

      const segments = this._ctx.api
        .getAll()
        .features.filter(
          (feature) => feature.properties._type === constants.FEATURE_TYPES.NETWORK_SEGMENT
        );

      for (let elevator of elevators) {
        const connectedSegments = segments.filter(
          (segments) =>
            segments.properties.fromJunction === elevator.id ||
            segments.properties.toJunction === elevator.id
        );

        if (connectedSegments.length) {
          // replace the elevator with a normal junction and reconnect the segments
          const normalJunction = this.newFeature({
            type: 'Feature',
            properties: {
              ...elevator.properties,
              type: constants.JUNCTION_TYPES.NORMAL
            },
            geometry: {
              type: 'Point',
              coordinates: [...elevator.coordinates]
            }
          });

          this.addFeature(normalJunction);

          for (let segment of connectedSegments) {
            if (segment.properties.fromJunction === elevator.id) {
              this._ctx.api.setFeatureProperty(segment.id, 'fromJunction', normalJunction.id);
            }
            if (segment.properties.toJunction === elevator.id) {
              this._ctx.api.setFeatureProperty(segment.id, 'toJunction', normalJunction.id);
            }
          }
        }
      }
    }
  }
};

SimpleSelectModeOverride.dragMove = function(state, e) {
  if (!store.getters['venue/editingVenueConnections']) {
    state.isMoved = true;

    e.originalEvent.stopPropagation();

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation.lng,
      lat: e.lngLat.lat - state.dragMoveLocation.lat
    };

    this.detachElevatorsFromNetwork();

    // EDGE and NETWORK_SEGMENT cannot move on their own
    let movableFeatures = this.getSelected().filter((feature) => !isEdgeOrNetworkSegment(feature));
    const selectedPoints = this.getSelected().filter(
      (feature) =>
        isNodeOrJunction(feature) ||
        feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE
    );

    this.keepDistance(state, movableFeatures, delta);

    moveFeatures(movableFeatures, delta);

    if (!e.originalEvent.altKey && selectedPoints.length <= 1) {
      this.snap(state, movableFeatures, delta);
    }

    this.moveEdges(state);

    this.getSelected()
      .filter((feature) => feature.properties.type === constants.FACILITY_TYPES.ELLIPSE)
      .map((ellipse) => ellipse.properties.center)
      .forEach((center) => {
        center[0] += delta.lng;
        center[1] += delta.lat;
      });

    state.dragMoveLocation = e.lngLat;
  }
};

SimpleSelectModeOverride.onTrash = function() {
  if (!store.getters['venue/editingVenueConnections']) {
    let selectedNodes = this.getSelected().filter((feature) => isNodeOrJunction(feature));
    const edges = this._ctx.api
      .getAll()
      .features.filter((feature) => isEdgeOrNetworkSegment(feature));

    for (let node of selectedNodes) {
      if (
        !(
          node.properties._type === constants.FEATURE_TYPES.JUNCTION &&
          node.properties.type === constants.JUNCTION_TYPES.STAIRS
        ) &&
        !(
          node.properties._type === constants.FEATURE_TYPES.JUNCTION &&
          node.properties.type === constants.JUNCTION_TYPES.ELEVATOR
        ) &&
        !(
          node.properties._type === constants.FEATURE_TYPES.JUNCTION &&
          node.properties.type === constants.JUNCTION_TYPES.ENTRY_POINT
        )
      ) {
        edges
          .filter(
            (edge) =>
              edge.properties[getFromPropertyName(edge.properties._type)] === node.id ||
              edge.properties[getToPropertyName(edge.properties._type)] === node.id
          )
          .forEach((edge) => {
            this.select(edge.id);
          });
      }
    }

    this.deleteFeature(this.getSelectedIds());
    this.fireActionable();
  }
};

SimpleSelectModeOverride.toDisplayFeatures = function(state, geojson, display) {
  geojson.properties.active = this.isSelected(geojson.properties.id)
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  display(geojson);

  this.fireActionable();

  if (!store.getters['venue/editingVenueConnections']) {
    if (
      geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT
    ) {
      return;
    }

    const shouldCreateSupplementaryPoints = [
      constants.FEATURE_TYPES.FACILITY,
      constants.FEATURE_TYPES.ZONE
    ].includes(geojson.properties.user__type);
    if (shouldCreateSupplementaryPoints) {
      const supplementaryPoints = createSupplementaryPoints(geojson);
      supplementaryPoints.forEach(display);
    }
  }
};

export default SimpleSelectModeOverride;
