import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import {
  bearing as turfBearing,
  destination as turfDestination,
  distance as turfDistance
} from '@turf/turf';
import constants from '~/shared/constants';
import { getFromPropertyName, getToPropertyName } from '~/shared/utils';

const POSITION = {
  START: 'start',
  END: 'end'
};

export const NODE_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const EDGE_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

/**
 * Returns edges that are connected to a single node.
 * In the returned object nodes' id will be the keys
 * and the values will be arrays of edges
 * If drawMode is present the connected edges' endpoint
 * connecting to the node will be updated
 */
export function getEdgesByNodes({ edges, nodes, type, drawMode, existingEdges }) {
  const edgesByNodes = {};

  for (const node of nodes) {
    const { id, coordinates } = node;

    const edgesFromNode = edges.filter(
      (edge) => edge.properties[getFromPropertyName(edge.properties._type)] === id
    );

    edgesByNodes[id] = edgesFromNode.map((edge) =>
      processEdgeForMeasureGuide({
        edge,
        node,
        type,
        position: POSITION.START,
        existingEdges
      })
    );

    if (drawMode) {
      edgesFromNode.forEach((edge) => {
        drawMode.getFeature(edge.id).updateCoordinate('0.0', coordinates[0], coordinates[1]);
      });
    }

    const edgesToNode = edges.filter(
      (edge) => edge.properties[getToPropertyName(edge.properties._type)] === id
    );
    edgesByNodes[id].push(
      ...edgesToNode.map((edge) =>
        processEdgeForMeasureGuide({
          edge,
          node,
          type,
          position: POSITION.END,
          existingEdges
        })
      )
    );

    if (drawMode) {
      edgesToNode.forEach((edge) => {
        drawMode.getFeature(edge.id).updateCoordinate('1.0', coordinates[0], coordinates[1]);
      });
    }
  }

  return edgesByNodes;
}

/**
 * Return the edge with calculated values that are used
 * when displaying and placing measure guides
 */
export function processEdgeForMeasureGuide({ edge, node, position, type, existingEdges }) {
  const currentEdgeNode = { id: node.id, coordinates: node.coordinates, position, type };

  if (existingEdges) {
    // check if edge is a primary edge
    const isPrimaryEdge = existingEdges.find((primaryEdge) => primaryEdge.id === edge.id);
    if (isPrimaryEdge) {
      return {
        ...isPrimaryEdge,
        nodes: [...isPrimaryEdge.nodes, currentEdgeNode]
      };
    }
  }

  const bearing =
    position === POSITION.START
      ? turfBearing(edge.geometry.coordinates[0], edge.geometry.coordinates[1])
      : turfBearing(edge.geometry.coordinates[1], edge.geometry.coordinates[0]);
  const oldNodes = edge.nodes || [];
  return {
    ...edge,
    nodes: [...oldNodes, currentEdgeNode],
    calculated: {
      length: turfDistance(edge.geometry.coordinates[0], edge.geometry.coordinates[1]),
      bearing: bearing < 0 ? bearing + 360 : bearing,
      centerPoint: [
        (edge.geometry.coordinates[0][0] + edge.geometry.coordinates[1][0]) / 2,
        (edge.geometry.coordinates[0][1] + edge.geometry.coordinates[1][1]) / 2
      ],
      type
    }
  };
}

/**
 * Returns the angle difference between two edges.
 * nextEdge is located clockwise direction from edge
 */
export function calculateAngleDifference(edgeBearing, nextEdgeBearing) {
  let bearing = nextEdgeBearing - edgeBearing;
  return bearing < 0 ? 360 - edgeBearing + nextEdgeBearing : bearing;
}

/**
 * Returns a point feature.
 * Its coordinates can be used to place measure guide angle labels
 * and its properties can be used to adjust the labels display
 */
export function calculateAngleMeasureGuidePosition({ angle, leftEdge, rightEdge, nodePosition }) {
  const leftEdgeLength = leftEdge.calculated.length;
  const rightEdgeLength = rightEdge.calculated.length;
  const measureGuidePositionDistance = (leftEdgeLength / 4 + rightEdgeLength / 4) / 2;
  let measureGuidePositionBearing = leftEdge.calculated.bearing + angle / 2;
  if (measureGuidePositionBearing > 180) {
    measureGuidePositionBearing -= 360;
  }
  return turfDestination(nodePosition, measureGuidePositionDistance, measureGuidePositionBearing, {
    properties: {
      guide_angle: Math.round(angle) + '°'
    }
  });
}

/**
 * Puts the measure guides on the map.
 * They will be points without symbol but with label
 */
export function addMeasureGuideToMap({ drawMode, state, coordinates, properties }) {
  let guide = drawMode.newFeature({
    type: 'Feature',
    geometry: { coordinates, type: 'Point' },
    properties: {
      _type: constants.FEATURE_TYPES.MEASURE_GUIDE,
      measure_guides: true,
      ...properties
    }
  });

  drawMode.addFeature(guide);
  state.measureGuides.push(guide);
  drawMode.select(guide.id);
  drawMode.map.fire(Constants.events.CREATE, {
    features: [guide.toGeoJSON()],
    skipHistory: true
  });
}

/**
 * Calculates angle difference and measure position for a pair of edges
 * and puts the measure guide on the map.
 */
export function createAngleMeasureGuides({ node, edge, nextEdge, state, drawMode }) {
  const diffAngle = calculateAngleDifference(edge.calculated.bearing, nextEdge.calculated.bearing);

  const measureGuidePosition = calculateAngleMeasureGuidePosition({
    angle: diffAngle,
    leftEdge: edge,
    rightEdge: nextEdge,
    nodePosition: node.coordinates
  });

  addMeasureGuideToMap({
    drawMode,
    state,
    coordinates: measureGuidePosition.geometry.coordinates,
    properties: {
      guide_angle: measureGuidePosition.properties.guide_angle
    }
  });
}

/**
 * Deletes measure guides from state and removes the related geometries
 * from the map
 */
export function deleteMeasureGuides(state, drawMode) {
  // delete existing measure guides
  if (state.measureGuides) {
    for (const guide of state.measureGuides) {
      drawMode.deleteFeature(guide.id, { silent: true });
    }
  }
  state.measureGuides = [];
}
